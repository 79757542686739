'use client';

import {
  ThemeProvider as NextThemesProvider,
  useTheme as useNextThemes,
} from 'next-themes';
import { type ThemeProviderProps } from 'next-themes';
import { Toaster } from 'sonner';

export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  return <NextThemesProvider {...props}>{children}</NextThemesProvider>;
}

export const ToasterWithTheme = () => {
  const theme = useTheme();
  return <Toaster theme={theme.isDark ? 'dark' : 'light'} />;
};

export const useTheme = () => {
  const theme = useNextThemes();

  const getCurrentTheme = () => {
    if (theme.theme === 'system') {
      return theme.systemTheme as 'dark' | 'light';
    }

    return theme.theme as 'dark' | 'light';
  };

  const currentTheme = getCurrentTheme();

  return {
    theme: currentTheme,
    toggle: () => theme.setTheme(currentTheme === 'dark' ? 'light' : 'dark'),
    setDark: () => theme.setTheme('dark'),
    setLight: () => theme.setTheme('light'),
    setSystem: () => theme.setTheme('system'),
    isDark: currentTheme === 'dark',
    isLight: currentTheme === 'light',
  };
};
