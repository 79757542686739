'use client';

import { useState } from 'react';
import { Loader } from '~/components/atoms/loader/Loader';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '~/components/ui/alert-dialog';
import { Input } from '~/components/ui/input';
import { Typo } from '~/components/ui/typography';

export type ConfirmationDialogProps = {
  title: string;
  description?: string;
  actionLabel?: string;
  cancelLabel?: string;
  loading?: boolean;
  onAction?: () => void;
  onCancel?: () => void;
  confirmationText?: string;
};

export const ProviderConfirmationDialog = ({
  title,
  actionLabel = 'Yes',
  cancelLabel = 'Cancel',
  loading,
  confirmationText,
  description,
  onCancel,
  onAction,
}: ConfirmationDialogProps) => {
  const [text, setText] = useState('');
  return (
    <AlertDialog open={true}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          {description ? (
            <AlertDialogDescription>{description}</AlertDialogDescription>
          ) : null}
        </AlertDialogHeader>
        {confirmationText ? (
          <div>
            <Typo className="text-muted-foreground">
              Write <b className="text-foreground">{confirmationText}</b> to perform
              this action.
            </Typo>
            <Input value={text} onChange={(e) => setText(e.target.value)} />
          </div>
        ) : null}
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>{cancelLabel}</AlertDialogCancel>
          <AlertDialogAction
            disabled={confirmationText ? text !== confirmationText : false}
            onClick={onAction}
          >
            {loading ? <Loader /> : actionLabel}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
