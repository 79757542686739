'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { SessionProvider, useSession } from 'next-auth/react';
import { NuqsAdapter } from 'nuqs/adapters/next/app';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect, type PropsWithChildren } from 'react';
import { AlertModal } from '~/features/modal/ModalProvider';
import { ThemeProvider, ToasterWithTheme } from '~/features/theme/ThemeProvider';
import { env } from '~/lib/env';

// TanStack Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

// PostHog
if (typeof window !== 'undefined') {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
  });
}

export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={queryClient}>
        <SessionProvider>
          <NuqsAdapter>
            <ThemeProvider
              attribute="class"
              defaultTheme="system"
              enableSystem
              disableTransitionOnChange
            >
              {children}

              {/* Inject toasts */}
              <ToasterWithTheme />

              {/* Inject alert dialogs */}
              <AlertModal />

              {/* Identify user for posthog */}
              <IdentifyUser />
            </ThemeProvider>
          </NuqsAdapter>
        </SessionProvider>
      </QueryClientProvider>
    </PostHogProvider>
  );
};

const IdentifyUser = () => {
  const session = useSession();

  useEffect(() => {
    if (!session.data?.user) {
      return;
    }

    posthog.identify(session.data.user.id, {
      email: session.data.user.email,
      name: session.data.user.name,
    });
  }, [session.data?.user]);

  return null;
};
